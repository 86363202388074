import React from 'react'
import {Link} from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const Card = ({data}) => {
  const imageData = data.images[0].fixed
  return (
    <Link to={`/${data.slug}`} className="text-grayDarkest">
      <BackgroundImage
        fixed={imageData}
        className={"flex hide-visible item-center items-center justify-center items-center align-center m-4 hover:opacity-50"}
      >
        <p className="text-xl">{data.title}</p>
        <div className="background-overlay test opacity-75" />
      </BackgroundImage>
    </Link>
  )
}

export default Card