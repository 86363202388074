import React from "react"
import {graphql} from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"

const Portfolio = (props) => (
  <Layout>
    <SEO title="Portfolio" />
    <h1 className="relative orange-underline text-center mb-8">Portfolio</h1>
    <div className="container flex flex-wrap flex-col justify-center md:flex-row ">
    {props.data.allContentfulPortfolioWork.nodes.map(post => (
      <Card key={post.slug} data={post} />
      ))}
    </div>

  </Layout>
)

export default Portfolio

export const query = graphql`
query {
  allContentfulPortfolioWork(sort: {order: DESC, fields: updatedAt}, filter: {categories: {eq: "Project"}}) {
    nodes {
      slug
      title
      images {
        file {
          fileName
          url
        }
        fixed(width: 300, height: 300) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      contentful_id
      createdAt
    }
  }
}
`